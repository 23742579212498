import React from 'react';

import styles from './Submenu.module.css';

interface SubmenuProps {
    className?: string;
    children: Array<JSX.Element> | JSX.Element;
}
const Submenu = ({ children, className }: React.PropsWithChildren<SubmenuProps>) => {
    return <div className={[className, styles.submenu].filter(Boolean).join(' ')}>{children}</div>;
};

export default Submenu;
