import PropTypes from 'prop-types';

import styles from './Submenu.module.css';

const Submenu = ({ children, className }) => {
    return <div className={[className, styles.submenu].filter(Boolean).join(' ')}>{children}</div>;
};
Submenu.propTypes = {
    className: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};
export default Submenu;
