import { useContext } from 'react';

import CustomLink from '@components/global/CustomLink';

import imgHeroPNG366 from '@public/global/header/customers-366.png';
import imgHeroWEBP366 from '@public/global/header/customers-366.webp';
import imgHeroPNG733 from '@public/global/header/customers-733.png';
import imgHeroWEBP733 from '@public/global/header/customers-733.webp';

import useTranslation from '@hooks/useTranslation';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { MenuContext } from '../../../Header';
import { MenuDialogContext } from '../../MenuDialog';
import styles from './Custom.module.css';

const Custom = ({ darkMode = false }) => {
    const { t } = useTranslation('base/_blocks/header_menu');
    const { closeSubMenu } = useContext(MenuDialogContext);
    const { closeMenu } = useContext(MenuContext);

    return (
        <div className={clsx(styles.custom, darkMode ? styles.darkMode : '')}>
            <picture className="rheader-new-menu-promo-img">
                <source
                    type="image/webp"
                    srcSet={`${imgHeroWEBP366.src} 366w, ${imgHeroWEBP733.src} 733w`}
                    sizes="(min-width: 733px) 733px, 100vw"
                />
                <source
                    type="image/png"
                    srcSet={`${imgHeroPNG366.src} 366w, ${imgHeroPNG733.src} 733w`}
                    sizes="(min-width: 733px) 733px, 100vw"
                />
                <img
                    loading="lazy"
                    width={imgHeroWEBP366.width}
                    height={imgHeroWEBP366.height}
                    src={imgHeroPNG366.src}
                    alt=""
                />
            </picture>
            <p
                dangerouslySetInnerHTML={{
                    __html: t('2022HeaderMenuItemCustomStoriesDesc'),
                }}
            ></p>
            <CustomLink
                href={{ pathname: 'customers' }}
                onClick={() => {
                    closeSubMenu();
                    closeMenu();
                }}
            >
                {t('2022HeaderMenuItemCustomStoriesLink')} <span className={styles.arrow}>›</span>
            </CustomLink>
        </div>
    );
};
Custom.propTypes = {
    darkMode: PropTypes.bool,
};
export default Custom;
