import { useContext } from 'react';

import { useRouter } from 'next/router';

import CustomLink from '@components/global/CustomLink';

import { ReactComponent as SVGApi } from '@public/global/header/icons/api.svg';
import { ReactComponent as SVGBlog } from '@public/global/header/icons/blog.svg';
import { ReactComponent as SVGCst } from '@public/global/header/icons/cst.svg';
import { ReactComponent as SVGHelpCenter } from '@public/global/header/icons/help_center.svg';
import { ReactComponent as SVGResources } from '@public/global/header/icons/resources.svg';
import { ReactComponent as SVGWebinarsLibrary } from '@public/global/header/icons/webinars_library.svg';

import useTranslation from '@hooks/useTranslation';
import { laravelRouteFeaturesSupport, laravelRouteHelp, laravelRouteWebinarLibrary } from '@static_components/laravelLinks';
import PropTypes from 'prop-types';

import { MenuContext } from '../../Header';
import { MenuDialogContext } from '../MenuDialog';
import Submenu from '../Submenu';
import SubmenuList from '../SubmenuList';
import SubmenuListItem from '../SubmenuListItem';
import Custom from './Custom';
import styles from './SubmenuResources.module.css';

const SubmenuResources = ({ darkMode = false }) => {
    const { t } = useTranslation('base/_blocks/header_menu');
    const { query, locale, pathname } = useRouter();
    const { closeSubMenu } = useContext(MenuDialogContext);
    const { closeMenu } = useContext(MenuContext);

    return (
        <Submenu className={styles.submenu}>
            <SubmenuList
                label={<span>{t('2022HeaderMenuItemHeaderLearnAndGrow')}</span>}
                darkMode={darkMode}
            >
                <SubmenuListItem
                    darkMode={darkMode}
                    icon={<SVGBlog />}
                    label={
                        pathname !== '/blog' ? (
                            <div>
                                <div>
                                    <CustomLink
                                        href={{ pathname: 'blog' }}
                                        onClick={() => {
                                            closeSubMenu();
                                            closeMenu();
                                        }}
                                    >
                                        {t('2022HeaderMenuItemBlog')}
                                    </CustomLink>
                                    <p>{t('2022HeaderMenuItemBlogDesc')}</p>
                                </div>
                            </div>
                        ) : (
                            <div>
                                <div>
                                    <span>{t('2022HeaderMenuItemBlog')}</span>
                                    <p>{t('2022HeaderMenuItemBlogDesc')}</p>
                                </div>
                            </div>
                        )
                    }
                />
                <SubmenuListItem
                    darkMode={darkMode}
                    icon={<SVGResources />}
                    label={
                        pathname !== '/resources' || Object.keys(query).length > 0 ? (
                            <div>
                                <div>
                                    <CustomLink
                                        href={{ pathname: 'resources' }}
                                        onClick={() => {
                                            closeSubMenu();
                                            closeMenu();
                                        }}
                                    >
                                        {t('2022HeaderMenuItemResources')}
                                    </CustomLink>
                                    <p>{t('2022HeaderMenuItemResourcesDesc')}</p>
                                </div>
                            </div>
                        ) : (
                            <div>
                                <div>
                                    <span>{t('2022HeaderMenuItemResources')}</span>
                                    <p>{t('2022HeaderMenuItemResourcesDesc')}</p>
                                </div>
                            </div>
                        )
                    }
                />
                <SubmenuListItem
                    darkMode={darkMode}
                    icon={<SVGWebinarsLibrary />}
                    label={
                        <div>
                            <div>
                                <a href={laravelRouteWebinarLibrary(locale)}>{t('2022HeaderMenuItemWebinars')}</a>
                                <p>{t('2022HeaderMenuItemWebinarsDesc')}</p>
                            </div>
                        </div>
                    }
                />
            </SubmenuList>
            <SubmenuList
                label={<span>{t('2022HeaderMenuItemHeaderHelp')}</span>}
                darkMode={darkMode}
            >
                <SubmenuListItem
                    darkMode={darkMode}
                    icon={<SVGCst />}
                    label={
                        <div>
                            <div>
                                <a href={laravelRouteFeaturesSupport(locale)}>{t('2022HeaderMenuItemSupport')}</a>
                                <p>{t('2022HeaderMenuItemSupportDesc')}</p>
                            </div>
                        </div>
                    }
                />
                <SubmenuListItem
                    darkMode={darkMode}
                    icon={<SVGHelpCenter />}
                    label={
                        <div>
                            <div>
                                <a href={laravelRouteHelp(locale)}>{t('2022HeaderMenuItemHelp')}</a>
                                <p>{t('2022HeaderMenuItemHelpDesc')}</p>
                            </div>
                        </div>
                    }
                />
                <SubmenuListItem
                    darkMode={darkMode}
                    icon={<SVGApi />}
                    label={
                        <div>
                            <div>
                                <a
                                    rel="nofollow"
                                    href="https://apidocs.getresponse.com/v3"
                                >
                                    {t('2022HeaderMenuItemApi')}
                                </a>
                                <p>{t('2022HeaderMenuItemApiDesc')}</p>
                            </div>
                        </div>
                    }
                />
            </SubmenuList>
            <SubmenuList
                label={<span>{t('2022HeaderMenuItemHeaderCustomStories')}</span>}
                darkMode={darkMode}
            >
                <Custom darkMode={darkMode} />
            </SubmenuList>
        </Submenu>
    );
};
SubmenuResources.propTypes = {
    darkMode: PropTypes.bool,
};

export default SubmenuResources;
