import React, { Children } from 'react';

import styles from './SubmenuList.module.css';

interface SubmenuListProps {
    label?: JSX.Element;
    darkMode?: boolean;
    horizontal?: boolean;
    children: Array<JSX.Element | null> | JSX.Element | null;
}
const SubmenuList = ({ children, horizontal = false, label, darkMode = false }: React.PropsWithChildren<SubmenuListProps>) => {
    const arrayChildren = Children.toArray(children);
    return (
        <div className={[!(arrayChildren.length > 1) ? styles.custom : '', darkMode ? styles.darkMode : '', horizontal ? styles.horizontal : styles.vertical].filter(Boolean).join(' ')}>
            {label && <div className={styles.label}>{label}</div>}

            {arrayChildren.length > 1 ? (
                <ul className={styles.list}>
                    {arrayChildren.map((child, i) => (
                        <li key={i}>{child}</li>
                    ))}
                </ul>
            ) : (
                children
            )}
        </div>
    );
};

export default SubmenuList;
