import React, { useEffect } from 'react';

import { useRouter } from 'next/router';

import TransText from 'next-translate/TransText';

import Trans from '@components/Trans';
import Underline from '@components/ui/Underline';
import { CTAStartFreeCreateAccount } from '@components/ui/design2023/CTAStartFreeCreateAccount';

import useTranslation from '@hooks/useTranslation';

import Counter from '../Counter';
import styles from './StickyBanner.module.css';

interface StickyBannerProps {
    accountsLeft: number | null;
}

declare global {
    interface Window {
        dataLayer: any;
    }
}

export default function StickyBanner({ accountsLeft }: StickyBannerProps) {
    const { t } = useTranslation('promo/black_friday_2024/TopBanner/index');
    const { locale } = useRouter();
    useEffect(() => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ event: 'bfcm24_promo_banner_viewed', placement: 'top banner' });
    }, []);

    const handleClick = () => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ event: 'bfcm24_promo_banner_clicked', placement: 'top banner' });
    };

    return (
        <div className={styles.banner}>
            <div className={styles.container}>
                <div className={styles.title}>
                    <Trans
                        i18nKey="title"
                        ns="promo/black_friday_2024/TopBanner/index"
                        components={{
                            small: <small />,
                            p: <p />,
                        }}
                    />
                </div>
                <div className={styles.header}>
                    <TransText
                        text={t('header').replaceAll(' class="gr-u"', '')}
                        components={{
                            u: <Underline />,
                            span: <span />,
                        }}
                    />
                </div>
                {accountsLeft != null && (
                    <p className={styles.counter}>
                        <Trans
                            i18nKey="accountsLeft"
                            components={{
                                counter: <Counter count={accountsLeft} />,
                            }}
                            ns="promo/black_friday_2024/TopBanner/index"
                        />
                    </p>
                )}
                <div className={styles.buttonWrap}>
                    <CTAStartFreeCreateAccount
                        size={'medium'}
                        label={t('button')}
                        color="yellow"
                        params={{ version: 'centered' }}
                        className={styles.button}
                        onClick={handleClick}
                        arrow={false}
                    />
                </div>
            </div>
        </div>
    );
}
