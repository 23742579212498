import { createContext, useEffect, useRef, useState } from 'react';

import { ReactComponent as SVGChevronMobile } from '@public/global/header/chevron-mobile.svg';
import { ReactComponent as SVGChevron } from '@public/global/header/chevron.svg';

import clsx from 'clsx';
import PropTypes from 'prop-types';

import styles from './MenuDialog.module.css';

export const MenuDialogContext = createContext();

export const MenuDialog = ({ label, children, darkMode = false }) => {
    const dialogRef = useRef();
    const buttonRef = useRef();
    const [dialogVisible, setDialogVisible] = useState(false);
    const [isDesktop, setIsDesktop] = useState(true);

    useEffect(() => {
        const mql = window.matchMedia('(min-width: 900px)');
        setIsDesktop(mql.matches);
        const handleChange = (e) => {
            setIsDesktop(e.matches);
        };
        mql.addEventListener('change', handleChange);
        return () => {
            mql.removeEventListener('change', handleChange);
        };
    }, []);
    useEffect(() => {
        const header = document.querySelector('[data-additional-attribute="has-new-layout"]');
        if (header) {
            if (dialogVisible) {
                header.setAttribute('data-has-dialog', 'open');
            } else {
                header.setAttribute('data-has-dialog', 'closed');
            }
        }
    }, [dialogVisible]);
    return (
        <>
            <button
                className={styles.button}
                ref={buttonRef}
                onMouseEnter={() => isDesktop && setDialogVisible(true)}
                onClick={() => setDialogVisible(!dialogVisible)}
                onBlur={(e) => {
                    isDesktop && setDialogVisible(false);
                }}
                onMouseLeave={(e) => {
                    if (!(e.relatedTarget instanceof HTMLElement) || !dialogRef.current.contains(e.relatedTarget)) {
                        isDesktop && setDialogVisible(false);
                    }
                }}
            >
                <div className={styles.cloak}></div>
                <div className={styles.wayback}></div>
                <span>
                    {label}
                    <SVGChevron
                        width="4"
                        height="8"
                    />
                    <SVGChevronMobile
                        width="15"
                        height="9"
                    />
                </span>
            </button>
            <MenuDialogContext.Provider value={{ closeSubMenu: () => setDialogVisible(false) }}>
                <dialog
                    className={clsx(styles.dialog, darkMode ? styles.darkMode : '')}
                    ref={dialogRef}
                    open={dialogVisible}
                    onMouseLeave={(e) => {
                        if (e.relatedTarget !== buttonRef.current) {
                            setDialogVisible(false);
                        }
                    }}
                >
                    <div className={styles.dialogContainer}>{children}</div>
                </dialog>
            </MenuDialogContext.Provider>
        </>
    );
};

MenuDialog.propTypes = {
    darkMode: PropTypes.bool,
    label: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};
