import React from 'react';

import defaultStyles from './Counter.module.css';

interface CounterProps {
    count: number;
    styles?: ElementProps['styles'] & {
        counter: string;
    };
}
interface ElementProps {
    num: number;
    styles?: {
        element: string;
    };
}

const Element = ({ num, styles }: ElementProps) => {
    return <span className={styles?.element ? styles.element : defaultStyles.element}>{num}</span>;
};

export default function Counter({ count, styles }: CounterProps) {
    return (
        <span className={styles?.counter ? styles.counter : defaultStyles.counter}>
            {Array.from(count.toString()).map((num, i) => (
                <Element
                    key={i}
                    num={+num}
                    styles={styles}
                />
            ))}
        </span>
    );
}
