import { cloneElement } from 'react';

import clsx from 'clsx';
import PropTypes from 'prop-types';

import styles from './SubmenuListItem.module.css';

const SubmenuListItem = ({ icon, label, badge, darkMode = false }) => {
    const styledIcon = cloneElement(icon, {
        className: styles.icon,
        key: 1,
        color: '#00baff',
    });
    if (['span', 'div'].includes(label.type)) {
        return cloneElement(label, {
            className: clsx(styles.item, darkMode ? styles.darkMode : ''),
            children: [styledIcon, label.props.children],
        });
    }

    return (
        <span className={clsx(styles.item, darkMode ? styles.darkMode : '')}>
            {styledIcon}
            {label}
            {typeof badge === 'object' && badge}
        </span>
    );
};
SubmenuListItem.propTypes = {
    darkMode: PropTypes.bool,
    icon: PropTypes.object.isRequired,
    label: PropTypes.node.isRequired,
    badge: PropTypes.node,
};

export default SubmenuListItem;
