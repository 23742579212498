import React from 'react';

import styles from './Badge.module.css';

interface BadgeProps {
    label?: string;
}

const Badge = ({ label }: BadgeProps) => {
    return <span className={styles.badge}>{label}</span>;
};

export default Badge;
