import React, { useContext, useEffect, useState } from 'react';

import { useRouter } from 'next/router';

import CustomLink from '@components/global/CustomLink';

import { ReactComponent as SVGAiRecommendations } from '@public/global/header/productMenu/ai_recommendations.svg';
import { ReactComponent as SVGAutofunnel } from '@public/global/header/productMenu/autofunnel.svg';
import { ReactComponent as SVGAutomation } from '@public/global/header/productMenu/automation.svg';
import { ReactComponent as SVGAutoresponders } from '@public/global/header/productMenu/autoresponders.svg';
import { ReactComponent as SVGEcommerceIntegrations } from '@public/global/header/productMenu/commerce_integrations_cart.svg';
import { ReactComponent as SVGContentCreators } from '@public/global/header/productMenu/content_creators.svg';
import { ReactComponent as SVGCourses } from '@public/global/header/productMenu/courses.svg';
import { ReactComponent as SVGEmailMarketing } from '@public/global/header/productMenu/email_marketing.svg';
import { ReactComponent as SVGLandingPages } from '@public/global/header/productMenu/landing_page.svg';
import { ReactComponent as SVGPaidAds } from '@public/global/header/productMenu/paid_ads.svg';
import { ReactComponent as SVGPopupCreator } from '@public/global/header/productMenu/popups_and_forms.svg';
import { ReactComponent as SVGPremiumNewsletter } from '@public/global/header/productMenu/premium_newsletter.svg';
import { ReactComponent as SVGSignupForms } from '@public/global/header/productMenu/signup_forms.svg';
import { ReactComponent as SVGSmsMarketing } from '@public/global/header/productMenu/sms_marketing.svg';
import { ReactComponent as SVGWebinars } from '@public/global/header/productMenu/webinars.svg';
import { ReactComponent as SVGWebPush } from '@public/global/header/productMenu/webpush.svg';
import { ReactComponent as SVGWesiteBuilder } from '@public/global/header/productMenu/website_builder.svg';

import useTranslation from '@hooks/useTranslation';
import {
    laravelRouteFeaturesConversionFunnel,
    laravelRouteFeaturesEmailMarketingAutoresponder,
    laravelRouteFeaturesListBuildingFormBuilder,
    laravelRouteFeaturesMarketingAutomation,
    laravelRouteFeaturesPaidAds,
    laravelRouteFeaturesPopupCreator,
    laravelRouteFeaturesProductRecommendations,
    laravelRouteFeaturesSmsTextAutomation,
    laravelRouteFeaturesWebPushNotifications,
    laravelRouteFeaturesWebinarSoftware,
    laravelRouteIntegrations,
} from '@static_components/laravelLinks';

import { MenuContext } from '..';
import { MenuDialogContext } from '../MenuDialog';
import Badge from './Badge';
import Submenu from './Submenu';
import SubmenuList from './SubmenuList';
import SubmenuListItem from './SubmenuListItem';
import styles from './SubmenuProducts.module.css';
import { ReactComponent as SVGArrow } from './arrow.svg';

interface SubmenuProductsProps {
    darkMode?: boolean;
}
const SubmenuProducts = ({ darkMode = false }: SubmenuProductsProps) => {
    const { t } = useTranslation('base/_blocks/header_menu/product_tab');
    const { locale, pathname } = useRouter();
    const { closeSubMenu } = useContext(MenuDialogContext);
    const { closeMenu } = useContext(MenuContext);
    const [anchorName, setAnchorName] = useState('');

    const onHashChange = () => {
        const hashParam = window.location.hash;
        if (hashParam !== anchorName) {
            setAnchorName(hashParam);
        }
    };

    useEffect(() => {
        onHashChange();
    }, [pathname, anchorName]);

    return (
        <Submenu className={darkMode ? styles.darkMode : ''}>
            <span className={styles.topLabelEmail}>{t('topLabel_emailMarketing')}</span>
            <SubmenuList
                label={
                    pathname !== '/solutions/lead-generation-software' ? (
                        <CustomLink
                            href={{ pathname: 'solutions/lead-generation-software' }}
                            onClick={() => {
                                setAnchorName('');
                                closeSubMenu();
                                closeMenu();
                            }}
                        >
                            {t('menu1_header')}
                            <span className={styles.chevron}>
                                <SVGArrow />
                            </span>
                        </CustomLink>
                    ) : (
                        <span className={styles.highlight}>{t('menu1_header')}</span>
                    )
                }
                darkMode={darkMode}
            >
                <SubmenuListItem
                    darkMode={darkMode}
                    icon={<SVGSignupForms />}
                    label={<a href={laravelRouteFeaturesListBuildingFormBuilder(locale)}>{t('menu1_item1')}</a>}
                />
                <SubmenuListItem
                    darkMode={darkMode}
                    badge={<Badge label={t('badge_AI')} />}
                    icon={<SVGLandingPages />}
                    label={
                        pathname !== '/features/landing-page-creator' ? (
                            <CustomLink
                                href={{ pathname: 'features/landing-page-creator' }}
                                onClick={() => {
                                    setAnchorName('');
                                    closeSubMenu();
                                    closeMenu();
                                }}
                            >
                                {t('menu1_item2')}
                            </CustomLink>
                        ) : (
                            <span>{t('menu1_item2')}</span>
                        )
                    }
                />
                <SubmenuListItem
                    darkMode={darkMode}
                    badge={<Badge label={t('badge_AI')} />}
                    icon={<SVGWesiteBuilder />}
                    label={
                        pathname !== '/features/website-builder' ? (
                            <CustomLink
                                href={{ pathname: 'features/website-builder' }}
                                onClick={() => {
                                    setAnchorName('');
                                    closeSubMenu();
                                    closeMenu();
                                }}
                            >
                                {t('menu1_item3')}
                            </CustomLink>
                        ) : (
                            <span>{t('menu1_item3')}</span>
                        )
                    }
                />
                <SubmenuListItem
                    darkMode={darkMode}
                    icon={<SVGAutofunnel />}
                    label={<a href={laravelRouteFeaturesConversionFunnel(locale)}>{t('menu1_item4')}</a>}
                />
            </SubmenuList>
            <SubmenuList
                label={
                    pathname !== '/solutions/customer-engagement' ? (
                        <CustomLink
                            href={{ pathname: 'solutions/customer-engagement' }}
                            onClick={() => {
                                setAnchorName('');
                                closeSubMenu();
                                closeMenu();
                            }}
                        >
                            {t('menu2_header')}
                            <span className={styles.chevron}>
                                <SVGArrow />
                            </span>
                        </CustomLink>
                    ) : (
                        <span className={styles.highlight}>{t('menu2_header')}</span>
                    )
                }
                darkMode={darkMode}
            >
                <SubmenuListItem
                    darkMode={darkMode}
                    badge={<Badge label={t('badge_AI')} />}
                    icon={<SVGEmailMarketing />}
                    label={
                        pathname !== '/features/email-marketing' ? (
                            <CustomLink
                                href={{ pathname: 'features/email-marketing' }}
                                onClick={() => {
                                    setAnchorName('');
                                    closeSubMenu();
                                    closeMenu();
                                }}
                            >
                                {t('menu2_item1')}
                            </CustomLink>
                        ) : (
                            <span>{t('menu2_item1')}</span>
                        )
                    }
                />
                <SubmenuListItem
                    darkMode={darkMode}
                    badge={<Badge label={t('badge_AI')} />}
                    icon={<SVGAutoresponders />}
                    label={<a href={laravelRouteFeaturesEmailMarketingAutoresponder(locale)}>{t('menu2_item2')}</a>}
                />
                <SubmenuListItem
                    darkMode={darkMode}
                    icon={<SVGAutomation />}
                    label={<a href={laravelRouteFeaturesMarketingAutomation(locale)}>{t('menu2_item3')}</a>}
                />
                <SubmenuListItem
                    darkMode={darkMode}
                    icon={<SVGWebPush />}
                    label={<a href={laravelRouteFeaturesWebPushNotifications(locale)}>{t('menu2_item4')}</a>}
                />
                <SubmenuListItem
                    darkMode={darkMode}
                    icon={<SVGSmsMarketing />}
                    label={<a href={laravelRouteFeaturesSmsTextAutomation(locale)}>{t('menu2_item5')}</a>}
                />
            </SubmenuList>
            <SubmenuList
                label={
                    pathname !== '/solutions/ecommerce-marketing' || (pathname === '/solutions/ecommerce-marketing' && anchorName === '#one-click-integrations') ? (
                        <CustomLink
                            href={{ pathname: 'solutions/ecommerce-marketing' }}
                            onClick={() => {
                                setAnchorName('');
                                closeSubMenu();
                                closeMenu();
                            }}
                        >
                            {t('menu3_header')}
                            <span className={styles.chevron}>
                                <SVGArrow />
                            </span>
                        </CustomLink>
                    ) : (
                        <span className={styles.highlight}>{t('menu3_header')}</span>
                    )
                }
                darkMode={darkMode}
            >
                <SubmenuListItem
                    darkMode={darkMode}
                    icon={<SVGEcommerceIntegrations />}
                    label={
                        pathname === '/solutions/ecommerce-marketing' && anchorName === '#one-click-integrations' ? (
                            <span>{t('menu3_item1')}</span>
                        ) : (
                            <CustomLink
                                href={{ pathname: 'solutions/ecommerce-marketing', hash: '#one-click-integrations' }}
                                onClick={() => {
                                    setAnchorName('#one-click-integrations');
                                    closeSubMenu();
                                    closeMenu();
                                }}
                            >
                                {t('menu3_item1')}
                            </CustomLink>
                        )
                    }
                />
                <SubmenuListItem
                    darkMode={darkMode}
                    icon={<SVGPopupCreator />}
                    label={<a href={laravelRouteFeaturesPopupCreator(locale)}>{t('menu3_item2')}</a>}
                />

                <SubmenuListItem
                    darkMode={darkMode}
                    icon={<SVGAiRecommendations />}
                    label={<a href={laravelRouteFeaturesProductRecommendations(locale)}>{t('menu3_item3')}</a>}
                />

                <SubmenuListItem
                    darkMode={darkMode}
                    icon={<SVGPaidAds />}
                    label={<a href={laravelRouteFeaturesPaidAds(locale)}>{t('menu3_item4')}</a>}
                />
            </SubmenuList>
            <span className={styles.topLabelContent}>{t('topLabel_contentMonetization')}</span>
            <SubmenuList
                label={
                    pathname !== '/solutions/selling-knowledge' || (pathname === '/solutions/selling-knowledge' && anchorName === '#toolkit') ? (
                        <CustomLink
                            href={{ pathname: 'solutions/selling-knowledge' }}
                            onClick={() => {
                                setAnchorName('');
                                closeSubMenu();
                                closeMenu();
                            }}
                        >
                            {t('menu4_header')}
                            <span className={styles.chevron}>
                                <SVGArrow />
                            </span>
                        </CustomLink>
                    ) : (
                        <span className={styles.highlight}>{t('menu4_header')}</span>
                    )
                }
                darkMode={darkMode}
            >
                <SubmenuListItem
                    darkMode={darkMode}
                    badge={<Badge label={t('badge_AI')} />}
                    icon={<SVGCourses />}
                    label={
                        pathname !== '/features/course-creator' || (pathname === '/features/course-creator' && anchorName === '#knowledge') ? (
                            <CustomLink
                                href={{ pathname: 'features/course-creator' }}
                                onClick={() => {
                                    setAnchorName('');
                                    closeSubMenu();
                                    closeMenu();
                                }}
                            >
                                {t('menu4_item1')}
                            </CustomLink>
                        ) : (
                            <span>{t('menu4_item1')}</span>
                        )
                    }
                />
                <SubmenuListItem
                    darkMode={darkMode}
                    icon={<SVGPremiumNewsletter />}
                    label={
                        pathname === '/solutions/selling-knowledge' && anchorName === '#toolkit' ? (
                            <span>{t('menu4_item2')}</span>
                        ) : (
                            <CustomLink
                                href={{ pathname: 'solutions/selling-knowledge', hash: '#toolkit' }}
                                onClick={() => {
                                    setAnchorName('#toolkit');
                                    closeSubMenu();
                                    closeMenu();
                                }}
                            >
                                {t('menu4_item2')}
                            </CustomLink>
                        )
                    }
                />
                <SubmenuListItem
                    darkMode={darkMode}
                    icon={<SVGContentCreators />}
                    label={
                        pathname === '/features/course-creator' && anchorName === '#knowledge' ? (
                            <span>{t('menu4_item3')}</span>
                        ) : (
                            <CustomLink
                                href={{ pathname: 'features/course-creator', hash: '#knowledge' }}
                                onClick={() => {
                                    setAnchorName('#knowledge');
                                    closeSubMenu();
                                    closeMenu();
                                }}
                            >
                                {t('menu4_item3')}
                            </CustomLink>
                        )
                    }
                />
                <SubmenuListItem
                    darkMode={darkMode}
                    icon={<SVGWebinars />}
                    label={<a href={laravelRouteFeaturesWebinarSoftware(locale)}>{t('menu4_item4')}</a>}
                />
            </SubmenuList>
            <span className={styles.labelMore}>{t('bottomLabel_more')}</span>
            <SubmenuList
                darkMode={darkMode}
                horizontal={true}
            >
                <SubmenuListItem
                    darkMode={darkMode}
                    label={
                        pathname !== '/about/whats-new' ? (
                            <CustomLink
                                href={{ pathname: 'about/whats-new' }}
                                onClick={() => {
                                    setAnchorName('');
                                    closeSubMenu();
                                    closeMenu();
                                }}
                            >
                                {t('menu5_item1')}
                            </CustomLink>
                        ) : (
                            <span>{t('menu5_item1')}</span>
                        )
                    }
                />
                <SubmenuListItem
                    darkMode={darkMode}
                    label={
                        pathname !== '/affiliate-programs' ? (
                            <CustomLink
                                href={{ pathname: 'affiliate-programs' }}
                                onClick={() => {
                                    setAnchorName('');
                                    closeSubMenu();
                                    closeMenu();
                                }}
                            >
                                {t('menu5_item2')}
                            </CustomLink>
                        ) : (
                            <span>{t('menu5_item2')}</span>
                        )
                    }
                />
                <SubmenuListItem
                    darkMode={darkMode}
                    label={
                        pathname !== '/pricing/trial' ? (
                            <CustomLink
                                href={{ pathname: 'pricing/trial' }}
                                onClick={() => {
                                    setAnchorName('');
                                    closeSubMenu();
                                    closeMenu();
                                }}
                            >
                                {t('menu5_item3')}
                            </CustomLink>
                        ) : (
                            <span>{t('menu5_item3')}</span>
                        )
                    }
                />
                <SubmenuListItem
                    darkMode={darkMode}
                    label={
                        pathname !== '/features' ? (
                            <CustomLink
                                href={{ pathname: 'features' }}
                                onClick={() => {
                                    setAnchorName('');
                                    closeSubMenu();
                                    closeMenu();
                                }}
                            >
                                {t('menu5_item4')}
                            </CustomLink>
                        ) : (
                            <span>{t('menu5_item4')}</span>
                        )
                    }
                />
                <SubmenuListItem
                    darkMode={darkMode}
                    label={<a href={laravelRouteIntegrations(locale)}>{t('menu5_item5')}</a>}
                />
                <SubmenuListItem
                    darkMode={darkMode}
                    label={
                        pathname !== '/move-to-getresponse' ? (
                            <CustomLink
                                href={{ pathname: 'move-to-getresponse' }}
                                onClick={() => {
                                    setAnchorName('');
                                    closeSubMenu();
                                    closeMenu();
                                }}
                            >
                                {t('menu5_item6')}
                            </CustomLink>
                        ) : (
                            <span>{t('menu5_item6')}</span>
                        )
                    }
                />
            </SubmenuList>
        </Submenu>
    );
};
export default SubmenuProducts;
