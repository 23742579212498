import styles from './MenuItem.module.css';

interface MenuItemProps {
    className?: string;
    darkMode?: boolean;
    align?: 'end';
}

const MenuItem = ({ children, align, className, darkMode = false }: React.PropsWithChildren<MenuItemProps>) => {
    return <div className={[styles.item, darkMode ? styles.darkMode : false, align === 'end' ? styles.alignEnd : false, className].filter(Boolean).join(' ')}>{children}</div>;
};

export default MenuItem;
